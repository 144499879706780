import React from 'react';
import Modal from 'rsuite/Modal';
import Button from 'rsuite/Button';
import Form from 'rsuite/Form';
import axios from 'axios';
import { mutate } from 'swr';
import { useFormik } from 'formik';
import * as yup from 'yup';
import toast from 'react-hot-toast';
import styles from 'public/styles/ChangePinNotification.module.css';
import { getUserToken } from 'utils';
import ErrorDiv from 'components/shared/ErrorDiv';
import { useSelector, useDispatch } from 'react-redux';
import { handleCloseCommDialog } from 'redux/actions/commActions';
import { handleSetWalletInfo } from 'redux/actions/userActions';

const CommissionToMainWallet = ({ user }) => {
    const { openCommissionDialog } = useSelector((state) => state.commData);
    const { userData } = useSelector((state) => state.userData);
    const userURL = userData?.isAgent
        ? `${process.env.NEXT_PUBLIC_BASE_URL}/agent/fetch-agent/${userData?.userId}`
        : `${process.env.NEXT_PUBLIC_BASE_URL}/user/fetch-user/${userData?.userId}`;
    const dispatch = useDispatch();
    const handleClose = () => dispatch(handleCloseCommDialog());

    const formik = useFormik({
        initialValues: {
            transactionPin: '',
            amount: '',
        },

        onSubmit: async (values, helpers) => {
            try {
                const token = getUserToken();
                const res = await axios.post(
                    `${process.env.NEXT_PUBLIC_BASE_URL}/wallet/transfer-commission-to-main-wallet`,
                    values,
                    {
                        headers: {
                            Authorization: `Bearer ${token}`,
                            'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                        },
                    },
                );

                // console.log('ressssssqqqqqqqqqqqqqqqqq', res);
                await dispatch(handleSetWalletInfo());
                toast.success('Funds Transfered Successfuly');
                helpers.setSubmitting(false);
                helpers.resetForm();
                handleClose();
            } catch (err) {
                console.log('errr', err);
                helpers.setSubmitting(false);
                if (err?.response?.data?.responsemessage?.msg) {
                    return toast.error(err?.response?.data?.responsemessage?.msg);
                }
                if (err?.response?.data?.responsemessage) {
                    return toast.error(err?.response?.data?.responsemessage);
                }
                if (err?.message) {
                    return toast.error(err?.message);
                }
            }
        },
        validationSchema: yup.object().shape({
            transactionPin: yup.string().min(4).max(4).required().label('Pin'),
            amount: yup.number().positive().label('Amount'),
        }),
    });
    return (
        <Modal
            style={{
                minHeight: '90vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
            backdrop={'static'}
            keyboard={false}
            open={openCommissionDialog}
            onClose={handleClose}
        >
            <Modal.Header>
                {/* <Modal.Title className={styles.modalTitle}>
                    Transfer funds from Comm. Wallet to Main Wallet
                </Modal.Title> */}
            </Modal.Header>

            <Modal.Body>
                <p className={styles.modalSubTitle}>
                    Enter amount you want to transfer from your commision wallet to your main wallet
                </p>
                <br />
                <div className="profile-container">
                    <Form fluid autoComplete="off" onSubmit={formik.handleSubmit}>
                        <input
                            autoComplete="false"
                            name="hidden"
                            type="text"
                            style={{ display: 'none' }}
                        />
                        <Form.Group controlId="amount">
                            <Form.ControlLabel>Amount</Form.ControlLabel>
                            <Form.Control
                                autoComplete="off"
                                name="amount"
                                type="number"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                    formik.setFieldValue('amount', e);
                                }}
                            />

                            {formik.errors.amount && formik.touched.amount ? (
                                <ErrorDiv msg={formik.errors.amount} />
                            ) : null}
                        </Form.Group>
                        <Form.Group controlId="transactionPin">
                            <Form.ControlLabel>Transaction Pin</Form.ControlLabel>
                            <Form.Control
                                autoComplete="off"
                                name="transactionPin"
                                type="password"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                    formik.setFieldValue('transactionPin', e);
                                }}
                            />

                            {formik.errors.transactionPin && formik.touched.transactionPin ? (
                                <ErrorDiv msg={formik.errors.transactionPin} />
                            ) : null}
                        </Form.Group>

                        <Form.Group className={styles.buttonDiv}>
                            <Button
                                className={styles.buttonStyle}
                                appearance="primary"
                                color="green"
                                type="submit"
                            >
                                {formik.isSubmitting ? 'Processing' : 'Continue'}
                            </Button>
                        </Form.Group>
                        <Form.Group className={styles.buttonDiv}>
                            <Button
                                className={styles.buttonStyle}
                                appearance="primary"
                                type="button"
                                onClick={handleClose}
                            >
                                Cancel Transfer
                            </Button>
                        </Form.Group>
                    </Form>
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default CommissionToMainWallet;
