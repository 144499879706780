import React from 'react';
import UserNav from '../UserComponents/UserNav';
import Footer from '../Footers/Footer';
import { Grid, Row, Col } from 'rsuite';
import LeftSidebar from '../shared/UserLeftSidebar/Lotteries/UserLeftSidebar';
import { useDispatch, useSelector } from 'react-redux';
import CommissionToMainWallet from 'components/CommissionToMainWallet/CommissionToMainWallet';
import TicketForms from 'components/HomepageComponents/TicketForms';
import RecentWinnersIndex from 'components/GamesComponents/RecentWinners/RecentWinnersIndex';
import styles from 'public/styles/Homepage.module.css';
import toast from 'react-hot-toast';
import axios from 'axios';
import { getUserToken } from 'utils';
import PlayBookedCodeModal from 'components/GamesComponents/PlayGame/PlayBookedCodeModal';
import TicketStatusModal from 'components/GamesComponents/PlayGame/TicketStatusModal';
import { useRouter } from 'next/router';
import {
    handleResetBetSlips,
    handleReSetComboDescription,
    handleResetGameBetType,
    handleSelectGame,
    handleSetLotteryCategory,
} from 'redux/actions/lotteryActions';
import DrawsBox from 'components/shared/UserLeftSidebar/DrawsBox/DrawsBox';

export default function UserLayout({ children, ...pageProps }) {
    const { openCommissionDialog } = useSelector((state) => state.commData);
    const dispatch = useDispatch();
    const router = useRouter();

    const [openTicketStatusModal, setOpenTicketStatusModal] = React.useState(false);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [ticketStatusData, setTicketStatusData] = React.useState({});
    const [bookedCodeValue, setBookedCodeValue] = React.useState('');
    const [ticketIdField, setTicketIdField] = React.useState('');
    const [bookedCodeData, setBookedCodeData] = React.useState({});
    const [openBookedCodeModal, setOpenBookedCodeModal] = React.useState(false);
    const handleClosePlayBookedCodeModal = () => setOpenBookedCodeModal(false);
    const handleCloseTicketStatusModal = () => setOpenTicketStatusModal(false);
    const handleFetchBookedCode = async () => {
        try {
            if (!bookedCodeValue) {
                return toast.error('Enter Booked Code To Proceed');
            }
            setIsSubmitting(true);
            const token = getUserToken();
            if (!token) {
                toast.error('Please, Login to Continue');
                return setTimeout(() => {
                    router.push('/login');
                }, 1200);
            }
            const res = await axios.get(
                `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-ticket-by-code/${bookedCodeValue}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                    },
                },
            );
            setBookedCodeData(res?.data?.data?.data);
            setOpenBookedCodeModal(true);
            setIsSubmitting(false);
            setBookedCodeValue('');
        } catch (error) {
            console.log(error);
            setIsSubmitting(false);
            if (error?.response?.data?.responsemessage?.msg) {
                return toast.error(error?.response?.data?.responsemessage?.msg);
            }
            if (error?.response?.data?.responsemessage) {
                return toast.error(error?.response?.data?.responsemessage);
            }
            toast.error(error.message);
        }
    };

    // Function to fetch a single ticket
    const handleFetchTicket = async () => {
        try {
            if (!ticketIdField) {
                return toast.error('Enter Ticket ID To Proceed');
            }
            setIsSubmitting(true);
            const token = getUserToken();
            const res = await axios.get(
                `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-ticket/${ticketIdField}`,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
                    },
                },
            );
            setTicketStatusData(res?.data?.data?.data);
            setOpenTicketStatusModal(true);
            setIsSubmitting(false);
            setTicketIdField('');
        } catch (error) {
            console.log(error);
            setIsSubmitting(false);
            if (error?.response?.data?.responsemessage?.msg) {
                return toast.error(error?.response?.data?.responsemessage?.msg);
            }
            if (error?.response?.data?.responsemessage) {
                return toast.error(error?.response?.data?.responsemessage);
            }
            toast.error(error.message);
        }
    };
    const handleChangeRouteByGame = async (item, lottery) => {
        dispatch(handleResetGameBetType());
        dispatch(handleResetBetSlips());
        dispatch(handleReSetComboDescription());

        dispatch(handleSetLotteryCategory(item?.category));
        router.push(
            `/lottery/${item?.lotteryId}/${item?.Lottery?.slug}?lotteryCategory=${item?.Lottery?.category}&gameId=${item?.gameId}`,
        );
        // router.push(
        //     `/?lotteryId=${item?.lotteryId}&slug=${item?.Lottery?.slug}&lotteryCategory=${item?.Lottery?.category}&gameId=${item?.gameId}`,
        // );
        dispatch(handleSelectGame(item));
    };
    return (
        <>
            <UserNav />
            {openCommissionDialog ? <CommissionToMainWallet /> : null}
            {openBookedCodeModal ? (
                <PlayBookedCodeModal
                    open={openBookedCodeModal}
                    handleClose={handleClosePlayBookedCodeModal}
                    bookedCodeData={bookedCodeData}
                />
            ) : null}
            {openTicketStatusModal ? (
                <TicketStatusModal
                    open={openTicketStatusModal}
                    handleClose={handleCloseTicketStatusModal}
                    ticketStatusData={ticketStatusData}
                />
            ) : null}
            <Grid fluid style={{ padding: '1px', backgroundColor: 'transparent' }}>
                <Row
                    style={{
                        width: '100%',
                        height: '60%',
                        display: pageProps.hideSideBar ? 'flex' : 'block',
                        justifyContent: pageProps.hideSideBar ? 'center' : '',
                    }}
                >
                    {!pageProps.hideSideBar && (
                        <Col className="leftside" xs={0} xsHidden smHidden md={5}>
                            <LeftSidebar />
                        </Col>
                    )}

                    <Col className="main" xs={24} md={14}>
                        {pageProps.showSideBar && (
                            <Grid style={{ width: '100%' }}>
                                <Row>
                                    <Col xs={24} mdHidden lgHidden>
                                        <LeftSidebar games={pageProps.games} />
                                    </Col>
                                </Row>
                            </Grid>
                        )}

                        <main
                            style={{
                                // backgroundColor: pageProps.transparent ? 'transparent' : '#C4C4C4',
                                backgroundColor: '#C4C4C4',
                                minHeight: '70vh',
                                padding: '0px 2px',
                            }}
                        >
                            {children}
                        </main>
                    </Col>
                    <Col md={5} xs={24} className={styles.recentWinnersSection}>
                        <section className={styles.mobileDrawSection}>
                            <DrawsBox handleChangeRouteByGame={handleChangeRouteByGame} />
                        </section>

                        <TicketForms
                            bookedCodeValue={bookedCodeValue}
                            handleFetchBookedCode={handleFetchBookedCode}
                            handleFetchTicket={handleFetchTicket}
                            setBookedCodeValue={setBookedCodeValue}
                            setTicketIdField={setTicketIdField}
                            ticketIdField={ticketIdField}
                            isSubmitting={isSubmitting}
                        />
                        <section className={styles.recentWinnersSection}>
                            <RecentWinnersIndex />
                        </section>
                    </Col>
                </Row>
            </Grid>

            <Footer />
        </>
    );
}
