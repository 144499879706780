import React from 'react';
import { getUserToken } from 'utils';
import useSWR from 'swr';
import TableLoader from 'components/shared/TableLoader/TableLoader';
import axios from 'axios';
import NumberFormat from 'react-number-format';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import styles from './RecentWinnersIndex.module.css';

dayjs.extend(relativeTime);

const RecentWinnersIndex = () => {
    const url = `${process.env.NEXT_PUBLIC_BASE_URL}/game/fetch-recent-winners?limit=20`;
    const fetcher = async () => {
        const token = getUserToken();
        const res = await axios.get(url, {
            headers: {
                Authorization: `Bearer ${token}`,
                'x-api-key': process.env.NEXT_PUBLIC_APP_API_KEY,
            },
        });

        return res.data?.data?.data;
    };

    const { data, error } = useSWR(url, fetcher);

    if (error) {
        console.log('error', error);
        return null;
    }

    if (!data) {
        return <TableLoader />;
    }

    return (
        <>
            <div style={{ padding: '10px' }}>
                <h6 className={styles.recentWinnersHeader}>Recent Winners</h6>
                <br />
                <div>
                    <table className={styles.recentWinnersTable}>
                        <tbody>
                            {data?.length > 0 &&
                                data?.map((item, idx) => (
                                    <tr
                                        key={`${item?.name}${item?.totalWinAmount}${idx}`}
                                        className={styles.recentWinnersRow}
                                    >
                                        <td>{item?.name}</td>
                                        <td>
                                            <NumberFormat
                                                value={Number(item?.totalWinAmount).toFixed(0)}
                                                displayType={'text'}
                                                className={styles.totalSlipAmountStyle}
                                                thousandSeparator={true}
                                                prefix={''}
                                                renderText={(value, props) => (
                                                    <span {...props}>&#8358;{value} </span>
                                                )}
                                            />
                                        </td>
                                        <td>{dayjs().to(dayjs(item?.dateWon))}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default RecentWinnersIndex;
