import React from 'react';
import Button from 'rsuite/Button';
import Input from 'rsuite/Input';
import styles from './TicketForms.module.css';

const TicketForms = ({
    bookedCodeValue,
    setBookedCodeValue,
    handleFetchBookedCode,
    ticketIdField,
    setTicketIdField,
    handleFetchTicket,
    isSubmitting,
}) => {
    return (
        <section className={styles.playBookedCodeSection}>
            <br />
            <h6 className={styles.bookedCodeTitle}>Play Booked Code</h6>
            <div className={styles.bookedCodeFormDiv}>
                <Input
                    autoComplete="off"
                    size="md"
                    placeholder="Enter Booked Code"
                    value={bookedCodeValue}
                    onChange={(e) => setBookedCodeValue(e)}
                />{' '}
                <Button
                    appearance="primary"
                    color="green"
                    className={styles.bookedCodeFormBtn}
                    onClick={() => handleFetchBookedCode()}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Fetch..' : 'GO'}
                </Button>
            </div>
            <br />
            <br />

            <h6 className={styles.bookedCodeTitle}>Search Ticket Status</h6>
            <div className={styles.bookedCodeFormDiv}>
                <Input
                    size="md"
                    autoComplete="off"
                    placeholder="Enter Ticket ID"
                    value={ticketIdField}
                    onChange={(e) => setTicketIdField(e)}
                />{' '}
                <Button
                    appearance="primary"
                    color="green"
                    className={styles.bookedCodeFormBtn}
                    onClick={() => handleFetchTicket()}
                    disabled={isSubmitting}
                >
                    {isSubmitting ? 'Fetch..' : 'GO'}
                </Button>
            </div>
        </section>
    );
};

export default TicketForms;
